




















































































































































































































































  import { debounceInput, isMobileScreenWidth } from '@/shared/helpers'
  import { CertificationAuthorityRole, IUserSubsegment } from '@/shared/model/certificationAuthority.model'
  import { IUser, UserRole } from '@/shared/model/user.model'
  import certificationService from '@/shared/services/certificationService'
  import userService from '@/shared/services/userService'
  import Vue from 'vue'
  import { mapActions, mapGetters } from 'vuex'

  export default Vue.extend({
    data () {
      return {
        search: undefined,
        selectedUser: null as IUserSubsegment | null,

        addCoworkerDialog: false,
        userAutocomplete: '',
        debounce: undefined as number | undefined,
        selectedCoworker: '' as string,
        selectedCoworkers: [] as IUser[],
        autocompleteError: '' as string,
        isMobile: isMobileScreenWidth(),

        authorityRoles: Object.values(CertificationAuthorityRole),
        editDialog: false,
        selectedRole: CertificationAuthorityRole.trainer,
      }
    },
    computed: {
      ...mapGetters({
        certificationAuthority: 'selectedCertificationAuthority',
        account: 'account',
        users: 'userStore/getList',
        certAuthorityError: 'certificationAuthorityStore/getError',
      }),
      headers (): any {
        return [
          {
            text: this.$t('username'),
            value: 'user.username',
          },
          {
            text: this.$t('firstname'),
            value: 'user.metadata.firstName',
          },
          {
            text: this.$t('lastname'),
            value: 'user.metadata.lastName',
          },
          {
            text: this.$t('email'),
            value: 'user.email',
          },
          {
            text: this.$t('roles'),
            value: 'authorityRoles[0]',
          },
          {
            sortable: false,
            text: this.$t('actions'),
            value: 'actions',
            align: 'end',
          },
        ]
      },
    },
    watch: {
      userAutocomplete: {
        async handler () {
          this.autocompleteError = ''
          this.debounce = debounceInput(this.debounce, this.getAllUsersForAutocomplete)
        },
      },
      certificationAuthority: {
        async handler () {
          await this.getAllUsersForAutocomplete()
        },
      },
    },
    async mounted () {},
    methods: {
      ...mapActions('certificationAuthorityStore', {
        updateCertificationAuthority: 'updateItem',
      }),
      async clickSave () {
        await this.updateCertificationAuthority({
          id: this.certificationAuthority._id,
          item: this.certificationAuthority,
        })

        if (this.certAuthorityError) {
          this.$toast.error(this.$t('dialog.an-unexpected-error-occured').toString())
        } else {
          this.$toast(this.$t('dialog.certification-authority-save-successfully').toString())
        }
        certificationService.getCertificationAuthorityById(this.$store, this.certificationAuthority._id)
      },
      async clickSaveCoworkers () {
        if (this.selectedCoworkers.length) {
          const users = [...this.certificationAuthority.users]

          for (const coworker of this.selectedCoworkers) {
            users.push({
              user: coworker,
              authorityRoles: [CertificationAuthorityRole.trainer],
            })
          }
          this.certificationAuthority.users = users

          await this.updateCertificationAuthority({
            id: this.certificationAuthority._id,
            item: this.certificationAuthority,
          })

          if (this.certAuthorityError) {
            this.$toast.error(this.$t('dialog.an-unexpected-error-occured').toString())
          } else {
            this.$toast(this.$t('dialog.certification-authority-save-successfully').toString())
            this.selectedCoworkers = []
          }
          certificationService.getCertificationAuthorityById(this.$store, this.certificationAuthority._id)
          this.addCoworkerDialog = false
        }
      },
      clickRemoveUser (user: IUser) {
        this.$confirm(
          `${this.$t('dialog.do-you-really-want-the-user-to')}
<strong>${user.username}</strong>
${this.$t('dialog.remove-from-certification-authority')}`,
          {
            color: 'error',
            title: this.$t('dialog.remove-trainer').toString(),
          },
        ).then(async (res) => {
          if (res) {
            if (user._id) {
              const index = this.certificationAuthority.users.findIndex((y: any) => y.user._id === user._id)
              if (index >= 0) {
                this.certificationAuthority.users.splice(index, 1)
              }
              await this.updateCertificationAuthority({
                id: this.certificationAuthority._id,
                item: this.certificationAuthority,
              })
              certificationService.getCertificationAuthorityById(this.$store, this.certificationAuthority._id)
            } else {
              console.error('Tried to remove user without id, this should not happen')
            }
          }
        })
      },
      addUserToCertificationAuthority () {
        if (this.selectedCoworker) {
          const user = this.users.find((x: IUser) => x._id === this.selectedCoworker)
          if (user) {
            if (this.certificationAuthority && this.certificationAuthority.users) {
              const foundUser = this.certificationAuthority.users.find((x: IUserSubsegment) => {
                if (x.user) {
                  return x.user._id === user._id
                }
                return false
              })

              if (foundUser) {
                this.autocompleteError = this.$t('dialog.this-user-is-already-in-this-certification-authority').toString()
              } else {
                if (!this.selectedCoworkers.find((x: IUser) => x._id === user._id)) {
                  this.selectedCoworkers.push(user)
                  this.selectedCoworker = ''
                } else {
                  this.autocompleteError = this.$t('dialog.this-user-is-already-selected').toString()
                }
              }
            }
          }
        }
      },
      userIsAllowedToEditCoworker: function (coworker: IUserSubsegment) {
        if (this.certificationAuthority) {
          let userIsCertAuthAdmin = false
          const userFromCertAuth = this.certificationAuthority.users.find((x: any) => x.user._id === this.account._id)

          if (userFromCertAuth && userFromCertAuth.authorityRoles) {
            userIsCertAuthAdmin = userFromCertAuth.authorityRoles.includes(CertificationAuthorityRole.authorityAdmin)
          }
          if (userIsCertAuthAdmin || this.account.roles.includes(UserRole.admin)) {
            return true
          }
        }
        return false
      },
      clickUpdateUser (item: IUserSubsegment) {
        // this.$router.push(`/app/users/${item._id}`, () => {})
        this.selectedUser = item
        if (item.authorityRoles) {
          this.selectedRole = item.authorityRoles[0]
        }
        this.editDialog = true
      },
      async clickSaveCertAuthRoles () {
        if (this.selectedUser && this.selectedRole) {
          this.selectedUser.authorityRoles = [this.selectedRole]
        }
        await this.clickSave()
        this.selectedUser = null
        this.editDialog = false
      },
      async getAllUsersForAutocomplete () {
        const query = {
          l: 10,
          lo: 0,
          sf: 'username',
          so: 'desc',
          search: { search: this.userAutocomplete },
        }
        await userService.getAllUsersPaginated(query)
      },
    },
  })
